// App.js
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm"
// const stripePromise = loadStripe("pk_test_51Q0jKN04axCdj4q4ij9eHpLuctRr5pzo2j8lPDp9C8BNowt44BeQOowOZtQK1uSJb1Pgy9XUNeaLyOBeb8Cqigpe00kazGxu2N");
const STRIPE_PK_KEY= process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

const stripePromise = loadStripe(STRIPE_PK_KEY);



function MakePayment({price}) {
  return (
    <Elements stripe={stripePromise}>
    <CheckoutForm price={price}/>
    </Elements>
  );
}

export default MakePayment;
