
import axios from 'axios';
import { API_BASE_URL, STRIPE_BASE_URL } from '../../constant';

const STRIPE_Sk_KEY= process.env.REACT_APP_STRIPE_SECURITY_KEY
class PaymentServices {

    MedCoinForContentCreator(userId) {
        return axios.post(API_BASE_URL + `api/Admin/AddMedCoinForContentCreator?userId=${userId}`);
    }

    PaymnetDone(userId, amount, token) {
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", userId);
        bodyFormData.append("Amount", parseFloat(amount));
        bodyFormData.append("PaymentId", token);
        return axios.post(API_BASE_URL + "api/Payment/MakePayment", bodyFormData);
    }
    AddBankDetail(obj) {
        console.log(STRIPE_Sk_KEY,"STRIPE_KEY")
        debugger
        return axios.post(STRIPE_BASE_URL + "accounts", {

            external_account: obj.currency,
            type: "express",
            email: obj.email,
            business_type: "individual",
            external_account: {
                object: 'bank_account',
                country: obj.country,
                currency: obj.currency,
                account_holder_name: obj.accountHolderName,
                account_holder_type: obj.accountHolderType,
                routing_number: obj.routingNumber,
                account_number: obj.accountNumber
            },
            capabilities: {
                card_payments:{
                    requested: true
                },
                transfers:{
                    requested: true
                }
            },
            individual: {
                email: obj.email,
                first_name: obj.first_name,
                last_name: obj.last_name,
                phone: obj.phone,
            },
            // business_profile: ""
        }, {
            
            headers: {
                'Authorization': `Bearer ${STRIPE_Sk_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }



    CreateAccountLink(obj) {
        return axios.post(STRIPE_BASE_URL + "account_links", obj, {
            headers: {
                'Authorization': `Bearer ${STRIPE_Sk_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    SaveBankId(userId, bankId) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", userId);
        bodyFormData.append("BankId", bankId);
        return axios.post(API_BASE_URL + "api/Payment/CreateAccoutId", bodyFormData);
    }
    GetCoonectAccountById(obj) {
        console.log("STRIPE_Sk_KEY",STRIPE_Sk_KEY)

        return axios.get(STRIPE_BASE_URL + `accounts/${obj}`, {
            headers: {
                'Authorization': `Bearer ${STRIPE_Sk_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    TransferPayment(obj) {
        return axios.post(STRIPE_BASE_URL + "transfers",obj, {
            headers: {
                'Authorization': `Bearer ${STRIPE_Sk_KEY}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    SaveTransactionId(userId, transactionId) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", userId);
        bodyFormData.append("TransactionId", transactionId);
        return axios.post(API_BASE_URL + "api/Payment/CreateAccoutId", bodyFormData);
    }
    
}

export default new PaymentServices();


