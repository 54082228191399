import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Q0jKN04axCdj4q4ij9eHpLuctRr5pzo2j8lPDp9C8BNowt44BeQOowOZtQK1uSJb1Pgy9XUNeaLyOBeb8Cqigpe00kazGxu2N');

export default function App() {

  const options = {
    // passing the client secret obtained from the Stripe Dashboard
    clientSecret:`sk_test_51Q0jKN04axCdj4q4KxENFYp8n24bJpIchIRWAesmCKo7NhZkfHM3FDYAczIqi3kPItxBhLMXicWRpi8AUnj8GeJy00INcLGVQv`,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};