import axios from 'axios';
import { API_BASE_URL } from '../../constant';

class QuizServices {

  getAllQuizes(object) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

    return axios.post(API_BASE_URL + `api/Quiz/GetFilteredQuestions?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, object);
  }
  CreateQuiz(object, content, optList, dpropdownList) {

    return axios.post(API_BASE_URL + `api/Quiz/AddQuestion?SID=${object.userId}`, {
      level: dpropdownList.LevelId,
      branchId: dpropdownList.BranchId,
      chapterId: dpropdownList.ChapterId,
      subChapterId: dpropdownList.SubChapterId,
      countryId: object.countryId,
      language: object.languageId,
      universityId: object.universityId,
      isPremium: object.isPremimum,
      questionOptions: optList,
      title: object.title,
      questionImageUrl: object.questionImageUrl,
      imageUrl: content.imageUrl,
      videoUrl: content.videoUrl,
      audioUrl: content.audioUrl,
      isContentPremium: content.contentType == true ? 1 : 0
    });
  }
  GetQuestionById(id) {
    return axios.get(API_BASE_URL + `api/Quiz/GetQuestionById?id=${id}`,);
  }
  DeleteQuestionById(id) {
    return axios.delete(API_BASE_URL + `api/Quiz/DeleteQuestion?id=${id}`,);
  }
  getQuizesByCount(count) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Quiz/GetQuestionsByCount?count=${10}`);
  }
  AddQuizQuestion(object) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

    return axios.post(API_BASE_URL + 'api/Quiz/AddQuizAnswers', object);
  }

  LikeQuestion(object, value, islike) {
    return axios.post(API_BASE_URL + 'api/Quiz/LikeQuestion', {
      questionId: value,
      isLike: islike == true ? false : true,
      userId: object.userId,
      isStudent: object.isStudent
    });
  }

  CommnetsQuestion(comment, qId, isComment, userId) {
    return axios.post(API_BASE_URL + 'api/Quiz/CommentQuestion', {
      questionId: qId,
      isComment: isComment,
      comment: comment,
      userId: userId
    });
  }
  BookmarkedQuestion(object, value, isBookmarked) {

    return axios.post(API_BASE_URL + 'api/Quiz/BookmarkQuestion', {
      questionId: value,
      isLike: isBookmarked == true ? false : true,
      userId: object.userId,
      isStudent: object.isStudent
    });
  }
  FlagdQuestion(object, value, isFlag) {

    return axios.post(API_BASE_URL + 'api/Quiz/FlagQuestion', {
      questionId: value,
      isLike: isFlag == true ? false : true,
      userId: object.userId,
      comment: object.comment,
      isStudent: object.isStudent
    });
  }

  UpdateView(id) {
    return axios.post(API_BASE_URL + `api/Quiz/UpdateView?id=${id}`)
  }

  UpdateCorrectionElement(content, user, QuesId) {
    
    return axios.post(API_BASE_URL + 'api/Quiz/UpdateCorrectionElement', {
      id: QuesId,
      correctionElementUserId: user.id,
      correctionElementUserName: user.firstName + "" + user.firstName,
      imageUrl: content.imageUrl == "" ? null : content.imageUrl,
      videoUrl: content.videoUrl == "" ? null : content.videoUrl,
      audioUrl: content.audioUrl == "" ? null : content.audioUrl,
    });
  }


}

export default new QuizServices();

