import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import AllFilterServices from "../../services/AllFilterServices";
import { useSelector, useDispatch } from "react-redux";
import AdminServices from "../../services/AdminServices";
import MakePayment from "../../payment/MakePayment";

const Premium = () => {
  const dispatch = useDispatch();
  const MyCreationList = useSelector((state) => state.Admin.ContentCreatorList);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [price, setPrice] = useState("");
  const [paymentType, setPaymentType] = useState("one-time"); // Initialize to "one-time"

  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);

  const [level, setLevel] = useState([]);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await AllFilterServices.getAllCountries();
      setCountryList(response.data.payload);
    };
    fetchCountries();
  }, []);

  const [universityList, setUniversityList] = useState([]);

  const GetCountry = async (e) => {
    const response = await AdminServices.GetUniversitiesByCountryId(
      e.target.value
    );
    setUniversityList(response.data.payload);
    setSelectedCountry(e.target.value);
  };

  const GetUniversity = async (e) => {
    const response = await AdminServices.GetLevelsByUniversityId(
      e.target.value
    );
    setLevel(response.data.payload);
    setSelectedUniversity(e.target.value);
  };

  const GetLevel = async (e) => {
    const response = await AdminServices.GetBranchesByLevelId(e.target.value);
    setSelectedLevel(e.target.value);
  };

  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = e.target.value;
    setPaymentType(selectedPaymentType);
    if (selectedPaymentType === "one-time") {
      setPrice(290); // Set one-time payment price
    } else if (selectedPaymentType === "subscription") {
      setPrice(40); // Set monthly subscription price
    }
  };

  return (
    <>
      <Row className="mt-0 mb-3">
        <Col md="4">
          <select className="form-control chapterApply" onChange={GetCountry}>
            <option value="">-- Select Country --</option>
            {countryList.map((value) => (
              <option value={value.id} key={value.id}>
                {value.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select
            className="form-control chapterApply"
            onChange={GetUniversity}
          >
            <option value="">-- Select University --</option>
            {universityList.map((value) => (
              <option value={value.id} key={value.id}>
                {value.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select className="form-control chapterApply" onChange={GetLevel}>
            <option value="">-- Select Year --</option>
            {level.map((value) => (
              <option value={value.id} key={value.id}>
                {value.title}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className="mt-0 mb-5 ml-5 mr-5">
        <Col md="12">
          <div className=" position-relative d-block mt-5">
            <h4 className="text-white mt-2 mb-2">Select Payment Type</h4>
            <div>
              <input
                id="one-time"
                type="radio"
                name="paymentType"
                value="one-time"
                //   checked={paymentType === "one-time"}
                onChange={handlePaymentTypeChange}
              />
              <label className="my-class" id="one-time" for="one-time">
                One-Time Payment (fr290 for 12 months)
              </label>
            </div>
            <div>
              <input
                id="subscription"
                type="radio"
                name="paymentType"
                value="subscription"
                //   checked={paymentType === "subscription"}
                onChange={handlePaymentTypeChange}
              />
              <label className="my-class" id="subscription" for="subscription">
                Monthly Subscription (fr40/month)
              </label>
            </div>
          </div>
        </Col>

        <Col md="12" className="mt-3">
          <MakePayment price={price} />
        </Col>
      </Row>
    </>
  );
};

export default Premium;
