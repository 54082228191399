import React, { useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import singup from "../../../src/assets/images/2.jpg";
import DoctorSigninForm from "./DoctorSigninForm";
import UserSignInForm3 from "./UserSignInForm3";
import AllFilterServices from "../services/AllFilterServices";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import FbLogin from "./SocialLogin/FbLogin"
import Spinner from "../spinner/Spinner";
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { Link } from "react-router-dom";
import UserSignInForm1 from "./UserSignInForm1";
import { useHistory } from "react-router-dom";
import AuthenticationServices from "../services/AuthenticationServices";

const UserSignInForm2 = (props) => {

  const [loading, setLoading] = useState(false)
  // console.log(props.location.state )
  const path = window.location.pathname;
  var FbUserObject = {
    FirstName: props.location?.state.fbUserData.FirstName == undefined || null ? props.userFormData1.FirstName : props.location.state.fbUserData.FirstName,
    Email: props.location?.state.fbUserData.Email == undefined || null ? props.userFormData1.Email : props.location.state.fbUserData.Email,
    LastName: props.location?.state.fbUserData.LastName == undefined || null ? props.userFormData1.LastName : props.location.state.fbUserData.LastName,
    ImagePath: props.location?.state.fbUserData.ImagePath == undefined || null ? props.userFormData1.ImagePath : props.location.state.fbUserData.ImagePath,
    Provider: props.location?.state.fbUserData.Provider == undefined || null ? props.userFormData1.Provider : props.location.state.fbUserData.Provider,
    UuId: props.location?.state.fbUserData.UuId == undefined || null ? props.userFormData1.UuId : props.location.state.fbUserData.UuId,
    PseudoName: props.location?.state.fbUserData.PseudoName == undefined || null ? props.userFormData1.Email : props.location.state.fbUserData.PseudoName
  }

  var userSignInForm1Data = props.userFormData1;

  const [StudentInterest, setStudentInterest] = useState([])
  const [DoctorInterest, setDoctorInterest] = useState([])

  const [StudentLevel, setStudentLevel] = useState([])
  const [DoctorLevel, setDoctorLevel] = useState([])
  async function fetchGetIntrestApi() {
    setLoading(true)
    
    var response = await AllFilterServices.getAllSignUpIntrests(userSignInForm1Data == undefined ? 0 : userSignInForm1Data.CountryId, userSignInForm1Data == undefined ? 0 : userSignInForm1Data.UniversityId)
    setStudentInterest(response.data.payload.studentInterests);
    setDoctorInterest(response.data.payload.doctorInterests);
    setStudentLevel(response.data.payload.studentLevels);
    setDoctorLevel(response.data.payload.doctorLevels);
    setLoading(false)
  }
  useEffect(() => {
    fetchGetIntrestApi()
    setShowData(inputField.IsStudent ? "student" : "doctor")
    if (inputField.IsStudent) {
      document.getElementById('studentsId').classList.add('medical-radiobtn')
      document.getElementById('studentsId').classList.remove('doctor-radiobtn')

      document.getElementById('DoctorId').classList.remove('medical-radiobtn')
      document.getElementById('DoctorId').classList.add('doctor-radiobtn')
    }
    else {
      document.getElementById('DoctorId').classList.add('medical-radiobtn')
      document.getElementById('DoctorId').classList.remove('doctor-radiobtn')

      document.getElementById('studentsId').classList.remove('medical-radiobtn')
      document.getElementById('studentsId').classList.add('doctor-radiobtn')

    }
  }, [])
  const [inputField, setInputField] = useState({
    IsStudent: props.userFormData2 ? props.userFormData2.IsStudent : true,
    Level: props.userFormData2?.Level,
    InterestIdsArr: props.userFormData2 ? props.userFormData2.InterestIdsArr : [],
  })
  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })
    var tempData = inputField;
    tempData[e.target.name] = e.target.value;
    console.log(tempData)

    if (tempData.Level == "" || tempData.InterestIdsArr.length == 0) {
      document.getElementById('nextBtn').classList.add('backgroundColor')
    }
    else {
      document.getElementById('nextBtn').classList.remove('backgroundColor')
      document.getElementById('nextBtn').classList.add('signupBtn')
    }
  }


  const [next, setNext] = useState()
  const [back, setBack] = useState()
  const [showData, setShowData] = useState(false)
  const showDoctorForm = (e) => {

    setShowData(e.target.value)

    if (e.target.value == "student") {
      document.getElementById('studentsId').classList.add('medical-radiobtn')
      document.getElementById('studentsId').classList.remove('doctor-radiobtn')

      document.getElementById('DoctorId').classList.remove('medical-radiobtn')
      document.getElementById('DoctorId').classList.add('doctor-radiobtn')
      inputField.IsStudent = true
    }
    else {

      inputField.IsStudent = false
      document.getElementById('DoctorId').classList.add('medical-radiobtn')
      document.getElementById('DoctorId').classList.remove('doctor-radiobtn')


      document.getElementById('studentsId').classList.remove('medical-radiobtn')
      document.getElementById('studentsId').classList.add('doctor-radiobtn')

    }

  }

  const [allIds, setAllIds] = useState([])
  const GetIntrestsIds = (e) => {
    var Id = e.target.getAttribute('data-questionId')

    if (e.target.checked == false) {
      var selectedIds = allIds.filter(function (obj) {
        return obj != Id;
      });
      setAllIds(selectedIds)
      document.getElementById('nextBtn').classList.add('backgroundColor')
    }
    else {
      var selectedIds = allIds;
      selectedIds.push(parseInt(Id))
      var tempData = inputField;
      tempData.InterestIdsArr = selectedIds;
      setAllIds(selectedIds)
      document.getElementById('nextBtn').classList.remove('backgroundColor')
      document.getElementById('nextBtn').classList.add('signupBtn')
    }

  }
  useEffect(() => {
    if (props.userFormData2?.Level) {
      document.getElementById('nextBtn').classList.remove('backgroundColor')
      document.getElementById('nextBtn').classList.add('signupBtn')
    }

  })
  const nextBtn = () => {

    if (inputField.Level == "" || inputField.Level == undefined || inputField.InterestIdsArr.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: 'PLease Fill the Complete Information',
      })
    }
    else {

      setNext(!next)

    }
  }
  const backToRoute = () => {
    history.push("/login");
  };
  const submitButton = async () => {
    // if (inputField.HospitalId == "" && inputField.UniversityId == "") {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Failed...',
    //     text: 'PLease Fill the Complete Information',
    //   })
    // }

    // else {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "You want be able to reverse it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // setLoading(true);

          var response = await AuthenticationServices.RegisterStudent(inputField, props.userFormData1);
          if (response.data.status === true) {
            Swal.fire("Added!", "" + response.data.message, "success");
            const loginRes = await AuthenticationServices.login(props.userFormData1.Email, props.userFormData1.Password)
            if (loginRes.data.status === true) {
              localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(loginRes.data.payload));
              history.push("/quiz");
              window.location.reload()
            }
          } else {
            Swal.fire("Error!", response.data.message, "danger");
          }
          // setLoading(false);
        }
      });

    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error,
      })
    }
    // }
  };
  let history = useHistory()
  const Goback = () => {
    if (window.location.pathname == "/userSignInForm2") {
      window.location.href = "/login";
    } else {
      setBack(!back)

    }
  }
  return (
    <>

      {back ||
        <div>
          {next ||
            <div className="main-wrap">
              <div className="row">
                <div
                  className="col-xl-5"
                  style={{
                    backgroundImage: `url(${singup})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* <img alt=""
                    src={singup}
                    className=" w-50 float-right picture"
                    style={{ marginRight: 80, marginTop: 15 }}
                  /> */}
                </div>

                <div className="col-xl-7 col md-7 align-items-center pl-0">
                  <div
                    className=" shadow-none border-0 ml-auto mr-auto login-card"
                    style={{ maxWidth: "unset" }}
                  >
                    <div className="card-body rounded-0 text-left">
                      <Link to="/userSignInForm1">
                        <BsFillArrowLeftSquareFill fontSize="2rem" color='#E84545' onClick={Goback} /> </Link>
                      <h2 className="d-flex flex-row justify-content-center align-items-center mb-3 personal mt-3 mb-5">
                        User Registration Details
                      </h2>

                      <h4 className="mb-2 personal">I am a</h4>
                      <Row>
                        <div className="col-6">
                          <div className="mb-3 medical-radiobtn" id="studentsId" style={{ width: "365px" }}>
                            <input type="radio" id="student" name="IsStudent" defaultChecked={inputField.IsStudent} value="student" onChange={showDoctorForm} />
                            <label htmlFor="student" className="ml-3">Student</label>
                          </div>

                        </div>
                        <div className="col-6">
                          <div className="mb-3 doctor-radiobtn" id="DoctorId">
                            <input type="radio" id="doctor" name="IsStudent" value="doctor" defaultChecked={!inputField.IsStudent} onChange={showDoctorForm} />
                            <label htmlFor="doctor" className="ml-3" >Doctor</label>
                          </div>
                        </div>
                      </Row>
                      {loading ? <Spinner /> :
                        <div>
                          {showData == "doctor" ?
                            <form>
                              <div className="dark">

                                <h5 className="personal m-3 pt-3">Doctor Level</h5>

                                <Row className="ml-3">
                                  {DoctorLevel?.map((item, key) => {
                                    return (
                                      <div className="col-4" key={key}>
                                        <div className="form-check mb-3">
                                          <input type="radio" id={item.title} name="Level" value={item.id} defaultChecked={inputField.Level == item.id} onChange={inputsHandler} />
                                          <label htmlFor={item.title}>{item.title}</label>
                                        </div>
                                      </div>
                                    )
                                  })}

                                </Row>
                                <h5 className="personal m-3 pt-3">
                                  Specialty:
                                </h5>
                                <Row className="ml-3">
                                  {DoctorInterest?.map((item, index) => {
                                    return (
                                      <div className="col-4" key={index}>
                                        <div className="form-check mb-3">
                                          <input type="radio" id={item.title} name="InterestIdsArr" value={item.id} defaultChecked={inputField.InterestIdsArr?.includes(item.id)} onChange={inputsHandler} />
                                          <label htmlFor={item.title}>{item.title}</label>
                                        </div>
                                      </div>
                                    )
                                  })}

                                </Row>
                              </div>
                            </form> :

                            <form>
                              <div className="dark">

                                <h5 className="personal m-3 pt-3">Student Level</h5>
                                <Row className="ml-3">
                                  {StudentLevel?.map((item, key) => {
                                    return (
                                      <div className="col-4" key={key}>
                                        <div className="form-check mb-3">
                                          <input type="radio" id={item.title} name="Level" value={item.id} defaultChecked={inputField.Level == item.id} onChange={inputsHandler} />
                                          <label htmlFor={item.title}>{item.title}</label>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </Row>
                                <h5 className="personal m-3">
                                  My specialties of interest are:
                                </h5>
                                <Row className="ml-3">
                                  {StudentInterest?.map((item, index) => {
                                    return (
                                      <div className="col-4" key={index}>
                                        <div className="form-check text-left mb-3 text-white">
                                          <input
                                            type="checkbox"
                                            name="InterestIdsArr"
                                            data-questionId={item.id}
                                            onChange={GetIntrestsIds}
                                            defaultChecked={inputField.InterestIdsArr?.includes(item.id)}
                                            className="float-left checkbox"
                                            style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                          />
                                          <label className="mt-1" id="">{item.title}</label>
                                        </div>
                                      </div>
                                    )
                                  })}

                                </Row>
                              </div>
                            </form>
                          }
                        </div>
                      }

                      {/* <button className='backgroundColor' id="nextBtn" onClick={nextBtn}> Next</button> */}
                      <button className='backgroundColor' id="nextBtn" onClick={submitButton}> Start Now</button>

                    </div>
                  </div>
                </div>

              </div>
            </div>}

          {next && <div>
            <div> {path == "/userSignInForm1" ? <UserSignInForm3 userFormData2={inputField} userFormData1={userSignInForm1Data} /> : < FbLogin userFormData2={inputField} userFormData1={FbUserObject} />} </div>
          </div>}
        </div>}
      {back && <UserSignInForm3 userFormData2={inputField} userFormData1={userSignInForm1Data} />}

    </>
  )
}

export default UserSignInForm2
