import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import singup from "../../../src/assets/images/4.PNG";
import { AiOutlineDownload } from "react-icons/ai"
import CardImg from "../../../src/assets/iCON/noun-business-2437536.png"
import AuthenticationServices from "../services/AuthenticationServices";
import AllFilterServices from "../services/AllFilterServices"
import Swal from "sweetalert2";
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useHistory } from "react-router-dom";
import UserSignInForm2 from "./UserSignInForm2";
import UserSignInForm1 from "./UserSignInForm1";
import AdminServices from "../services/AdminServices";
const UserSignInForm3 = (props) => {

  // console.log("1",props.userFormData1)
  // console.log("2",props.userFormData2)

  const [inputField, setInputField] = useState({
    StudentId: 0,
    HospitalId: 0,
    UniversityId: 0,
    IdentityImage: "",
    CountryId: props.userFormData1?.CountryId,
    Email: props.userFormData1?.Email,
    FirstName: props.userFormData1?.FirstName,
    LanguageId: props.userFormData1?.LanguageId,
    LastName: props.userFormData1?.LastName,
    Password: props.userFormData1?.Password,
    PseudoName: props.userFormData1?.PseudoName,
    StudentImage: props.userFormData1?.StudentImage,
    InterestIdsArr: props.userFormData2?.InterestIdsArr,
    IsStudent: props.userFormData2?.IsStudent,
    Level: props.userFormData2?.Level
  })

  var tempData = inputField;
  const inputsHandler = (e) => {

    setInputField({ ...inputField, [e.target.name]: e.target.value })
    tempData[e.target.name] = e.target.value;

    console.log(inputField)

    if (e.target.name == "UniversityId") {
      setIsStudent(true)
      if (inputField.UniversityId == "") {
        document.getElementById('nextBtn').classList.add('backgroundColor')
      }
      else {
        document.getElementById('nextBtn').classList.remove('backgroundColor')
        document.getElementById('nextBtn').classList.add('signupBtn')
      }
    }
    else {
      setIsStudent(false)
      if (inputField.HospitalId == "") {
        document.getElementById('nextBtn').classList.add('backgroundColor')
      }
      else {
        document.getElementById('nextBtn').classList.remove('backgroundColor')
        document.getElementById('nextBtn').classList.add('signupBtn')
      }
    }

  }
  console.log(inputField)
  const [UniversityList, setUniersityList] = useState([])
  async function fetchUniversityApi() {
    var response = await AdminServices.GetUniversitiesByCountryId(props.userFormData1?.CountryId)
    setUniersityList(response.data.payload);
  }
  const [HospitalList, setHospitalList] = useState([])
  async function fetchHospitalApi() {
    var response = await AdminServices.GetHopitalByCountryId(props.userFormData1?.CountryId)
    setHospitalList(response.data.payload);
  }
  useEffect(() => {
    fetchUniversityApi()
    fetchHospitalApi()
  }, [])

  const [selectedImage, setSelectedImage] = useState(null);
  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const imageChange = (e) => {
    let base64String = "";
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      inputField.IdentityImage = file

    }
  };
  let history = useHistory()
  const backToRoute = () => {
    history.push("/login");
  };

  const submitButton = async () => {
    if (inputField.HospitalId == "" && inputField.UniversityId == "") {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: 'PLease Fill the Complete Information',
      })
    }

    else {
      try {
        await Swal.fire({
          title: "Are you sure?",
          text: "You want be able to reverse it!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // setLoading(true);

            var response = await AuthenticationServices.RegisterStudent(inputField);
            if (response.data.status === true) {
              Swal.fire("Added!", "" + response.data.message, "success");
              backToRoute()
            } else {
              Swal.fire("Error!", response.data.message, "danger");
            }
            // setLoading(false);
          }
        });

      }

      catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
      }
    }
  };
  const [next, setNext] = useState()

  const nextBtn = () => {

    if (inputField.HospitalId == "" || inputField.UniversityId == "") {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: 'PLease Fill the Complete Information',
      })
    }
    else {

      setNext(!next)

    }
  }

  const styles = {
    image: {
      display: "flex",
      width: "50%",
      margin: "0px auto"
    },
  };
  const [back, setBack] = useState(false)
  const Goback = () => {
    setBack(!back)
  }
  const [isStudent, setIsStudent] = useState(true)
  return (
    <>
      {back ||
        <div className="main-wrap">
          {next ||
            <div className="row">
              <div
                className="col-xl-5 p-0 "
                style={{
                  backgroundImage: `url(${singup})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}>
                {/* <img alt=""
                  src={singup}
                  className=" w-50 float-right picture"
                  style={{ marginRight: 80, marginTop: 15 }}
                /> */}
              </div>
              <div className="col-xl-7 vh-100 align-items-center  overflow-hidden pl-0 ">
                <div
                  className="shadow-none border-0 ml-auto mr-auto login-card color"
                  style={{ maxWidth: "unset" }}
                >
                  <div className="card-body rounded-0 text-left">
                    <BsFillArrowLeftSquareFill fontSize="2rem" color='#E84545' onClick={Goback} />
                    <h1 className="d-flex flex-row justify-content-center align-items-center mb-3 mt-3 personal">
                      User Registration Details
                    </h1>

                    <h4 className=" pl-4 mb-2 personal">ID Information</h4>
                    <Row>
                      <div className="container">
                        <div className="row">

                          <div className="col-6">
                            <div className="form-group icon-input mb-3 registerUser">
                              {/* <i style={{ color: "#E84545" }} className="font-sm ti-home pr-0"></i> */}
                              <select type="select"
                                name="UniversityId"
                                id="UniversityId"
                                onChange={inputsHandler}
                                value={inputField.UniversityId}
                                className="form-control"
                              >
                                <option value="">Select University</option>
                                {
                                  UniversityList.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.name} </option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group icon-input mb-3 registerUser">
                              {/* <i style={{ color: "#E84545" }} className="font-sm ti-home pr-0"></i> */}
                              <select type="select"
                                name="HospitalId"
                                id="HospitalId"
                                onChange={inputsHandler}
                                value={inputField.HospitalId}
                                className="form-control"

                              >
                                <option value="">Select Hospital</option>
                                {
                                  HospitalList.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.name} </option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                    </Row>
                    {/* {isStudent == true ? */}
                    <div className="d-flex justify-content-center flex-column w-75 my-10 mx-auto rounded p-8 mt-2" style={{ overflowY: "hidden", backgroundColor: "#1D2226", }}>
                      {selectedImage == null ?
                        <>
                          <img alt="" src={CardImg} width="70px" className="mt-5 mx-auto" />
                          <div className="mb-3 mt-2">
                            <input
                              type='file'
                              id='file' ref={inputFile}
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={imageChange} />
                          </div>
                          <button onClick={onButtonClick} className="uploadBtn my-10 mx-auto "><AiOutlineDownload /> Upload ID</button>
                          <p className="text-danger text-center">* Please note you will not able to publish content or message other user if your account is not verified</p>
                        </>
                        :
                        <div>
                          {selectedImage && (
                            <div style={styles.preview}>
                              <img
                                src={URL.createObjectURL(selectedImage)}
                                style={styles.image}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </div>
                      }
                    </div>

                    {/* <Card className="dark">
                      {selectedImage == null ?
                        <CardBody>
                           <img alt="" src={CardImg} width="70px" className="CardImg mt-4" style={{ marginLeft: "300px" }} />
                          <div class="mb-3 mt-5">
                            <input
                              type='file'
                              id='file' ref={inputFile}
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={imageChange} />
                          </div>
                          <button onClick={onButtonClick} className="uploadBtn"><AiOutlineDownload /> Upload proof of Title (hospital badge, diploma..)</button>
                          <p className="text-danger">* Please note you will not able to publish content or message other user if your account is not verified</p>
                        </CardBody>
                        :
                        <div>
                          {selectedImage && (
                            <div style={styles.preview}>
                               <img alt=""
                                src={URL.createObjectURL(selectedImage)}
                                style={styles.image}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </div>
                      }
                    </Card> */}


                    {/* <button className='backgroundColor' id="nextBtn" onClick={submitButton}> Start Now</button> */}
                    <button className='backgroundColor' id="nextBtn" onClick={nextBtn}> Next</button>


                  </div>
                </div>
              </div>
            </div>}
        </div>}
      {back && <UserSignInForm1 userFormData1={props.userFormData1} userFormData2={props.userFormData2} />}

      {next && <UserSignInForm2 userFormData1={inputField} />}

    </>
  )
}

export default UserSignInForm3