
import axios from 'axios';
import { API_BASE_URL } from '../../constant';
class ProfileServices {


    GetStudentById(Id) {
        return axios.get(API_BASE_URL + `api/User/GetUserById?id=${Id}`);
    }

    GetFollowers(Id) {
        return axios.post(API_BASE_URL + `api/User/GetUserFollowers?UserId=${Id}`);
    }
    GetFollowing(Id) {
        return axios.post(API_BASE_URL + `api/User/GetUserFollowings?UserId=${Id}`);
    }

    GetPostByUserId(Id) {
        return axios.post(API_BASE_URL + `api/Social/GetUserSocialPosts?UserId=${Id}`);
    }
    GetBookMarkPostByUserId(Id) {
        return axios.post(API_BASE_URL + `api/Social/GetUserBookmarkedSocialPosts?UserId=${Id}`);
    }
    
    GetUserPostedQuestionById(Id) {
        return axios.get(API_BASE_URL + `api/Quiz/GetUserQuestions?UserId=${Id}`);
    }
    GetUserBookmarkedQuestionById(Id) {
        return axios.get(API_BASE_URL + `api/Quiz/GetUserBookmarkedQuestions?UserId=${Id}`);
    }
    
    GetUserPostedStudyMaterialById(Id) {
        return axios.get(API_BASE_URL + `api/StudyMaterial/GetUserStudyMaterials?UserId=${Id}`);
    }
    GetUserBookMarkedStudyMaterialById(Id) {
        return axios.get(API_BASE_URL + `api/StudyMaterial/GetUserBookmarkedStudyMaterials?UserId=${Id}`);
    }
   
    UpdateStudentData(object) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("Id", object.id);
        bodyFormData.append("FirstName", object.firstName);
        bodyFormData.append("LastName", object.lastName);
        bodyFormData.append("Email", object.email);
        bodyFormData.append("StudentIdentityPath", object.studentIdentityPath);
        bodyFormData.append("CountryId", object.countryId);
        bodyFormData.append("LanguageId", object.languageId);
        bodyFormData.append("UserLevel", object.studentLevelId==undefined?object.userLevel.id:object.studentLevelId);
        bodyFormData.append("UserInterest", object.InterestIdsArr==undefined?"": object.InterestIdsArr.toString());
        return axios.post(API_BASE_URL + "api/User/UpdateUser", bodyFormData)
    }
    UpdateDoctorData(object) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("Id", object.id);
        bodyFormData.append("FirstName", object.firstName);
        bodyFormData.append("LastName", object.lastName);
        bodyFormData.append("Email", object.email);
        bodyFormData.append("StudentIdentityPath", object.studentIdentityPath);
        bodyFormData.append("CountryId", object.countryId);
        bodyFormData.append("LanguageId", object.languageId);


        return axios.post(API_BASE_URL + "api/Doctor/UpdateDoctor", bodyFormData)
    }


    ChangeUserPassword(object) {
        var bodyFormData = new FormData();
         ;
        bodyFormData.append("Id", object.id);
        bodyFormData.append("OldPassword", object.oldPassword);
        bodyFormData.append("NewPassword", object.newPassword);

        return axios.post(API_BASE_URL + "api/User/ChangePassword", bodyFormData)
    }

    UpdateStudentProfile(object) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("Bio", object.Bio);
        bodyFormData.append("CoverImage", object.CoverImage);
        bodyFormData.append("ProfileImage", object.ProfileImage);
        bodyFormData.append("Id", object.Id);

        return axios.post(API_BASE_URL + "api/User/UpdateBio", bodyFormData)
    }
 

}

export default new ProfileServices();


