import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { QuizFilter } from '../quiz/QuizFilters';
import { Row } from "reactstrap";
import { useParams, useHistory, Link } from 'react-router-dom';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import moment from "moment";
import { FiYoutube } from "react-icons/fi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { TiFlashOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import Header from '../../components/Header';
import { StudyFilters } from "./StudyFilters";
import VideoMaterials from './videos/VideoMaterials';
import FlashCardsMaterial from './videos/flashcard/FlashCardsMaterial';
import DocumentsMaterial from './documents/DocumentsMaterial';
import { videoMaterial, ChnageTabs } from "../study/redux/features/StudySlice";
import createIcon from "../assests/iCON/noun-create-1409661.png";

const Study = () => {
  const localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  const data = useMemo(() => JSON.parse(localStorageObject), [localStorageObject]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [studyType, setStudyType] = useState("VIDEO");
  const [allTab, setAllTabs] = useState(null);

  const defaultVideoMaterialPayload = useMemo(() => ({
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    branchId: [],
    chapterId: [],
    subChapterId: [],
    LanguageId: [],
    CountryId: [],
    UniversityId: [],
    LevelId: [],
    Bookmarked: null,
    questionCount: 10,
  }), []);

  const loadStudyMaterial = useCallback((type) => {
    dispatch(videoMaterial({ ...defaultVideoMaterialPayload, type }));
    setStudyType(type);
  }, [dispatch, defaultVideoMaterialPayload]);

  useEffect(() => {
    loadStudyMaterial("VIDEO");
  }, [loadStudyMaterial]);

  const changeTabs = useCallback((e, tab) => {
    setAllTabs(tab);
    dispatch(ChnageTabs(0));

    if (tab === 1) loadStudyMaterial("VIDEO");
    if (tab === 2) loadStudyMaterial("FLASHCARD");
    if (tab === 3) loadStudyMaterial("DOCUMENT");
  }, [dispatch, loadStudyMaterial]);

  const changeRoute = useCallback(() => {
    if (allTab === null || allTab === 1) history.push("/create-video");
    if (allTab === 2) history.push("/create-flashcard");
    if (allTab === 3) history.push("/create-document");
  }, [allTab, history]);

  return (
    <>
      <Header />
      <Row className="quiz" style={{ minHeight: '100vh' }}>
        <StudyFilters type={studyType} />
        <div className="col-md-8 col-sm-12">
          <Tabs onClick={(e, tab) => changeTabs(e, tab)} activeTab="1" className="mt-4">
            <Tab title={<span style={{ fontWeight: "400" }}><FiYoutube className='mr-2' fontSize="1.8rem" />Videos</span>} className="mr-4">
              <VideoMaterials />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400" }}><TiFlashOutline fontSize="1.8rem" />Flashcards</span>} className="mr-4">
              <FlashCardsMaterial />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400", marginLeft: "-4px" }}><HiOutlineDocumentText fontSize="1.8rem" />Documents</span>} className="mr-4">
              <DocumentsMaterial />
            </Tab>
            <Tab
              className="ml-5"
              title={
                <button
                  disabled={data.isVerified} // Disable the button if data.isVerified is true
                  style={{ margin: "-25px", marginLeft: "-27px" }}
                  onClick={changeRoute}
                  className="study-material"
                >
                  <img alt="Add Study Material" src={createIcon} />Add Study Material
                </button>

              }
            >
            </Tab>
          </Tabs>
        </div>
      </Row>
    </>
  );
};

export default Study;
