// testing url
export const API_BASE_URL = "https://api.worldofdoctors.org/";

// export const API_BASE_URL = "http://ec2-16-62-107-4.eu-central-2.compute.amazonaws.com:81/";


// sherry bhai local url
// export const API_BASE_URL ="http://192.168.1.5:5064/";

export const STRIPE_BASE_URL="https://api.stripe.com/v1/";
export const FILE_TEMPLATE_BASE_URL ="https://api.worldofdoctors.org/Templates/";
export const SessionLogoutMsg = "User Login From Another Device";
export const AUDIO_BASE_URL = 'https://s3.amazonaws.com/nkenne.dev/';
export const BUCKET_NAME = 'worldofdoctors';

