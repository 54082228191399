import React, { useEffect, useState, useCallback } from "react";
import { Col } from "reactstrap";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import Swal from "sweetalert2";
import AdminServices from "../../../../services/AdminServices";

const AllUsers = ({ adminId, GetBranchesByLevelId }) => {
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);

  // Fetch all users based on branches and user type
  const fetchAllUsers = useCallback(async () => {
    try {
      const res = await AdminServices.GetAllUsersApi({
        levelId: GetBranchesByLevelId,
        userType: 0,
      });
      setAllUsers(res.data.payload);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [GetBranchesByLevelId]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  // Reusable confirmation prompt
  const confirmAction = async (title, text) => {
    const result = await Swal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
    return result.isConfirmed;
  };

  // Handle student verification toggle
  const toggleStudentVerified = async (isVerified, id) => {
    if (
      await confirmAction("Are you sure?", "You won't be able to revert this!")
    ) {
      try {
        const response = await AdminServices.VerifyById(id, isVerified);
        if (response.data.status) {
          Swal.fire("Success!", response.data.message, "success");
          setAllUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, isVerified } : user
            )
          );
        } else {
          Swal.fire("Problem!", response.data.message, "error");
        }
      } catch (error) {
        console.error("Error verifying user:", error);
      }
    }
  };

  // Handle student premium toggle
  const toggleStudentPremium = async (isPremium, id) => {
    debugger
    if (
      await confirmAction("Are you sure?", "You won't be able to revert this!")
    ) { 
      try { 
        const response = await AdminServices.MakeUserPremiumyById(
          id,
          isPremium,
          adminId
        );
        if (response.data.status) {
          Swal.fire("Success!", response.data.message, "success");
          setAllUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, isPremium } : user
            )
          );
        } else {
          Swal.fire("Problem!", response.data.message, "error");
        }
      } catch (error) {
        console.error("Error updating premium status:", error);
      }
    }
  };

  return (
    <Col md="12">
      <ReactTable
        style={{ fontSize: "12px", textAlign: "center" }}
        columns={[
          {
            Header: "User",
            accessor: "user",
            Cell: ({ row }) => (
              <div>{`${row._original.firstName} ${row._original.lastName}`}</div>
            ),
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "User Type",
            accessor: "userType",
            Cell: ({ row }) => (
              <div>{row._original.userType === 1 ? "Doctor" : "Student"}</div>
            ),
          },
          {
            Header: "Verified",
            accessor: "isVerified",
            Cell: ({ row }) => (
              <div className="d-flex justify-content-center">
                <Switch
                  onChange={(checked) =>
                    toggleStudentVerified(checked, row._original.id)
                  }
                  checked={row._original.isVerified}
                />
              </div>
            ),
          },
          {
            Header: "Premium",
            accessor: "isPremium",
            Cell: ({ row }) => (
              <div className="d-flex justify-content-center">
                <Switch
                  onChange={(checked) =>
                    toggleStudentPremium(checked, row._original.id)
                  }
                  checked={row._original.isPremium}
                />
              </div>
            ),
          },
        ]}
        defaultPageSize={5}
        className="-striped -highlight"
        data={allUsers}
      />
    </Col>
  );
};

export default AllUsers;
