import { API_BASE_URL } from "../../constant";
import axios from "axios";

class OtherUserProfileServices {

    getStudentById(id, key, isStudent) {
        return axios.get(API_BASE_URL + `api/User/GetUserById?Key=${key}&Id=${id}`)
    }
    GetAchievmentTrophies(id) {
        return axios.get(API_BASE_URL + `api/Profile/GetAchievementTrophies?userId=${id}`)
    }

    ContentCreatorTrophies(id) {
        return axios.get(API_BASE_URL + `api/Profile/GetContentCreatorTrophies?userId=${id}`)
    }

    AcademicTrophies(id) {
        return axios.get(API_BASE_URL + `api/Profile/GetAcademicTrophies?userId=${id}`)
    }
    SocialTrophies(id) {
        return axios.get(API_BASE_URL + `api/Profile/GetSocialTrophies?userId=${id}`)
    }


    FollowStudent(followerId, followingId, isFollowingStudent, status) {
         
        return axios.post(API_BASE_URL + 'api/User/FollowUser', {
            followerId: parseInt(followerId),
            followingId: parseInt(followingId),
            isFollowingStudent: isFollowingStudent,
            status: status
        });
    }
    FollowDoctor(followerId, followingId, isFollowingStudent, status) {
         
        return axios.post(API_BASE_URL + 'api/User/FollowUser', {
            followerId: parseInt(followerId),
            followingId: parseInt(followingId),
            isFollowingStudent: isFollowingStudent,
            status: status
        });
    }

    BlockStudent(blockerId, blockedId, isBlockedStudent, status) {
         
        return axios.post(API_BASE_URL + 'api/User/BlockUser', {
            blockerId: parseInt(blockerId),
            blockedId: parseInt(blockedId),
            isBlockedStudent: isBlockedStudent,
            status: status
        });
    }
    BlockDoctor(blockerId, blockedId, isBlockedStudent, status) {
         
        return axios.post(API_BASE_URL + 'api/User/BlockUser', {
            blockerId: blockerId,
            blockedId: blockedId,
            isBlockedStudent: isBlockedStudent,
            status: status
        });
    }

}

export default new OtherUserProfileServices()