import React, { useEffect, useState } from 'react'
import { Row, Col } from "reactstrap"
import { CountriesList } from '../../services/Data'
import PaymentServices from '../../services/PaymentServices';
import Swal from 'sweetalert2';
import { FaRegEdit } from "react-icons/fa";

const BankingDetails = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [input, setInput] = useState({
        userId: data.id,
        external_account: "usd",
        country: "",
        currency: "",
        accountNumber: "",
        routingNumber: "",
        accountHolderName: "",
        accountHolderType: "",
        type: "express",
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        phone: "",
        status: false
    })
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    useEffect(async () => {

        if (data.bankId === null || data.bankId === "") {
            setEdit(false)
        }
        else {
            setEdit(true)
            const getBankDetails = await PaymentServices.GetCoonectAccountById(data.bankId)
            console.log(getBankDetails.data)
            input.country = getBankDetails.data.country;
            input.accountHolderName = getBankDetails.data.external_accounts.data[0].account_holder_name;
            input.currency = getBankDetails.data.external_accounts.data[0].currency;
            input.routingNumber = getBankDetails.data.external_accounts.data[0].routing_number;
            input.accountHolderType = getBankDetails.data.external_accounts.data[0].account_holder_type;
            input.phone = getBankDetails.data.external_accounts.data[0].phone;
            input.status = getBankDetails.data.payouts_enabled;

        }
    }, [])

    const [accountLink, setAccountLink] = useState({
        account: "",
        refresh_url: "http://localhost:3000/wallet",
        return_url: "http://localhost:3000/wallet",
        type: "account_onboarding"
    })

    const inputHandler = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value })
    }
    const handleSubmit = async () => {
        setLoading(true)
        
        const res = await PaymentServices.AddBankDetail(input)
        accountLink.account = res.data.id;
        const connectAccountRes = await PaymentServices.CreateAccountLink(accountLink)
        if (!res.status === 200) {
            Swal.fire({
                icon: 'error',
                title: 'Failed...',
                text: "Error Savings your Bank details",
            })
            setLoading(false)

        }
        else {
            Swal.fire(
                'Success!',
                "Bank Account Adding  sucessfully",
                'success'
            )
            const BankAccountId = await PaymentServices.SaveBankId(data.id, res.data.id)
            setLoading(false)
            window.open(connectAccountRes.data.url, '_blank', 'noopener,noreferrer');
        }
    }
    return (
        <>
            <div className='d-flex justify-content-between mx-5'>
                {data.bankId!=null ?
                <span className={`badge pt-2  ${input.status ? "bg-success" : "bg-danger"}`}>
                    {input.status ? "Active" : "Inactive"}
                </span>
               :"" }
                <FaRegEdit fontSize="2rem" color='#E84545' onClick={() => setEdit(false)} />
            </div>

            <Row className='mt-3 mb-3 ml-5 mr-5'>
                <Col md="6">
                    <label>Select Country</label>
                    <select id="countrySelect" className='form-control chapterApply' name="country" onChange={inputHandler} value={input.country} disabled={edit}>
                        <option value="">Select a country</option>
                        {CountriesList.map((country, index) => (
                            <option key={index} value={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md="6">
                    <label>Select Currency</label>
                    <select id="countrySelect" className='form-control chapterApply' name="currency" onChange={inputHandler} value={input.currency} disabled={edit}>
                        <option value="">Select a currency</option>
                        {CountriesList.map((country, index) => (
                            <option key={index} value={country.currency}>
                                {country.currency}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md="6">
                    <label>Account Holder Name</label>
                    <input className='form-control chapterApply' name="accountHolderName" onChange={inputHandler} value={input.accountHolderName} disabled={edit} />
                </Col>
                <Col md="6">
                    <label>Routing Number</label>
                    <input className='form-control chapterApply' name="routingNumber" onChange={inputHandler} value={input.routingNumber} disabled={edit} />
                </Col>

                <Col md="6">
                    <label>Account Number</label>
                    <input className='form-control chapterApply' name="accountNumber" onChange={inputHandler} value={input.accountNumber} disabled={edit} />
                </Col>

                <Col md="6">
                    <label>Account Holder Type</label>
                    <select className='form-control chapterApply' name="accountHolderType" onChange={inputHandler} value={input.accountHolderType} disabled={edit} >
                        <option value="">Select a account holder type</option>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                    </select>
                </Col>
                <Col md="6">
                    <label>Phone Number</label>
                    <input className='form-control chapterApply' name="phone" onChange={inputHandler} value={input.phone} disabled={edit} />
                </Col>
            </Row>

            <div className='d-flex justify-content-center mt-5'>
                <button type="button" onClick={handleSubmit} className="btn" style={{ backgroundColor: "#E84545", color: "white", borderRadius: "6px", width: "200px" }}>{loading ? <div className='spinner' style={{ margin: "0px auto" }}></div> : edit ? "Update Bank Details" : "Add Bank Details"}</button>
            </div>
        </>
    )
}

export default BankingDetails