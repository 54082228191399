import axios from 'axios';
import { API_BASE_URL } from '../../constant';

class AdminServices {

    ApplyChapterAdmin(userId, chapterId) {
        return axios.post(API_BASE_URL + "api/Admin/ApplyChapterAdmin", {
            chapterId: chapterId,
            userId: userId
        });
    }

    ApplyCohortAdmin(userId, chapterId) {
         
        return axios.post(API_BASE_URL + "api/Admin/ApplyCohortAdmin", {
            levelId: chapterId,
            userId: userId
        });
    }
    GetAllUsersApi(obj) {
         
        return axios.post(API_BASE_URL + "api/User/GetAllUsers", {
            levelId: obj.levelId,
            userType: 0
        });
    }

    
    VoteChapterAdmin(userId, chapterId) {
        return axios.post(API_BASE_URL + "api/Admin/VoteChapterAdmin", {
            chapterId: chapterId,
            userId: userId
        });
    }
    VoteCohortAdmin(userId, levelId) {
        return axios.post(API_BASE_URL + "api/Admin/VoteCohortAdmin", {
            levelId: levelId,
            userId: userId
        });
    }

    GetChapterAdminCandidates(userId, chapterId) {
         
        return axios.post(API_BASE_URL + "api/Admin/GetChapterAdminCandidates", {
            chapterId: chapterId,
            userId: userId
        });
    }

    GetCohortAdminCandidates(userId, levelId) {
         
        return axios.post(API_BASE_URL + "api/Admin/GetCohortAdminCandidates", {
            levelId: levelId,
            userId: userId
        });
    }
    GetUniversitiesByCountryId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetUniversitiesByCountryId?Id=${id}`)
    }
    GetHospitalsByCountryId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetHospitalsByCountryId?Id=${id}`)
    }

    
    GetHopitalByCountryId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetHospitalsByCountryId?Id=${id}`)
    }
    GetLevelsByUniversityId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetLevelsByUniversityId?Id=${id}`)
    }

    GetBranchesByLevelId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetBranchesByLevelId?Id=${id}`)
    }
    GetChaptersByBranchId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetChaptersByBranchId?Id=${id}`)
    }
    GetSubChaptersByChapterId(id) {
        return axios.get(API_BASE_URL + `api/Util/GetSubChaptersByChapterId?Id=${id}`)

    }
    AddBranch(obj, Id) {
        return axios.post(API_BASE_URL + "api/Branch/AddBranch", {
            title: obj,
            parentId: Id
        })
    }

    EditBranch(obj) {
         
        return axios.post(API_BASE_URL + "api/Branch/UpdateBranch", {
            title: obj.title,
            parentId: obj.parentId
        })
    }

    MedCoinSettings(obj) {
         
        return axios.post(API_BASE_URL + "api/Admin/MedCoinSetting", obj)
    }
    RewardSettings(obj) {
         
        return axios.post(API_BASE_URL + "api/Admin/RewardSetting", obj)
    }


    EditChapter(obj) {
         
        return axios.post(API_BASE_URL + "api/Chapater/UpdateChapter", {
            title: obj.title,
            parentId: obj.parentId
        })
    }


    EditSubChapter(obj) {
         
        return axios.post(API_BASE_URL + "api/Chapater/UpdateSubChapter", {
            title: obj.title,
            parentId: obj.parentId
        })
    }


    AddChapter(obj, Id) {
        return axios.post(API_BASE_URL + "api/Chapater/AddChapter", {
            title: obj,
            parentId: Id
        })
    }

    AddSubChapter(obj, Id) {
        return axios.post(API_BASE_URL + "api/Chapater/AddSubChapter", {
            title: obj,
            parentId: Id
        })
    }

    GetAllBranch(id) {
        return axios.post(API_BASE_URL + `api/Branch/GetAllBranchesByLevelId?Id=${id}`)

    }
    GetAllChapter(id) {
         
        return axios.post(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchId?Id=${id}`)

    }


    GetAllSubChapter(id) {
         
        return axios.post(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterId?Id=${id}`)

    }

    DeleteBranch(id) {
        return axios.post(API_BASE_URL + `api/Branch/DeleteBranch?Id=${id}`)

    }
    DeleteChapter(id) {
        return axios.post(API_BASE_URL + `api/Chapater/DeleteChapter?Id=${id}`)

    }

    VerifyById(id,Status) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", id);
        bodyFormData.append("Status", Status);

        return axios.post(API_BASE_URL + "api/User/VerifyUser", bodyFormData);
    }


    MakeUserPremiumyById(id,status,adminId) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", id);
        bodyFormData.append("IsPremium", status);
        bodyFormData.append("AdminId", adminId);

        
        return axios.post(API_BASE_URL + "api/User/setPremium", bodyFormData);
    }


    DeleteSubChapter(id) {
        return axios.post(API_BASE_URL + `api/Chapater/DeleteSubChapter?Id=${id}`)

    }

    AddCohortStudyPlanFile(object, levelId) {
        var bodyFormData = new FormData();
        bodyFormData.append("Id", levelId);
        bodyFormData.append("File", object);
        bodyFormData.append("Replace", false);

        return axios.post(API_BASE_URL + "api/StudyPlan/UploadStudyPlan", bodyFormData)
    }

    AddUniversityFile(object, countryId) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("Id", countryId);
        bodyFormData.append("File", object);
        bodyFormData.append("Replace", false);

        return axios.post(API_BASE_URL + "api/Admin/UploadUniversities", bodyFormData)
    }


    AddHospitalFile(object, countryId) {
         
        var bodyFormData = new FormData();
        bodyFormData.append("Id", countryId);
        bodyFormData.append("File", object);
        bodyFormData.append("Replace", false);

        return axios.post(API_BASE_URL + "api/Admin/UploadHospitals", bodyFormData)
    }

    AddCurriculumHierarchyFile(object, id) {
        var bodyFormData = new FormData();
         ;
        bodyFormData.append("Id", id);
        bodyFormData.append("File", object);
        bodyFormData.append("Replace", false);

        return axios.post(API_BASE_URL + "api/University/UploadUniversityCurriculumHierarchy", bodyFormData)
    }
    getAllAdminCountries() {
        return axios.get(API_BASE_URL + "api/Country/GetAllCountriesList")
    }

    getAllAdminLanguage() {
        return axios.get(API_BASE_URL + "api/Language/GetAllLanguages")
    }


    UpdateCountryStatus(id, status) {
        var bodyFormData = new FormData();
         ;
        bodyFormData.append("Id", id);
        bodyFormData.append("Status", status ? 1 : 0);

        return axios.post(API_BASE_URL + "api/Country/UpdateStatus", bodyFormData)
    }

    UpdateLanguageStatus(id, status) {
        var bodyFormData = new FormData();
         ;
        bodyFormData.append("Id", id);
        bodyFormData.append("Status", status ? 1 : 0);

        return axios.post(API_BASE_URL + "api/Language/UpdateStatus", bodyFormData)
    }

    AddQuizQuestionFile(file,id,bool,userId) {
        var bodyFormData = new FormData();
         ;
        bodyFormData.append("File", file);
        bodyFormData.append("Replace", bool);
        bodyFormData.append("Id", id);
        bodyFormData.append("UserId", userId);
    
        
    
        return axios.post(API_BASE_URL + "api/Quiz/UploadQuestionFile", bodyFormData)
      }
      GetAllLevelBUniversityId(id){
         
        return axios.get(API_BASE_URL + `api/Util/GetLevelsByUniversityId?Id=${id}`)
      }
      
    UpdateLevels(obj) {
         
        return axios.post(API_BASE_URL + "api/Util/UpdateLevels",obj)
    }
}

export default new AdminServices();

