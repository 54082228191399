
import axios from 'axios';
import { API_BASE_URL } from '../../constant';
class AuthenticationService {

    login(email, password) {
         
        return axios.post(API_BASE_URL + "api/Account/Login", {
            email: email,
            password: password,
        })
    }

    async Fblogin(object) {
         
        var data = {
            universityId: parseInt(object.universityId),
            interestIds: object.isStudent == true ? object.interestIdsArr.join(", ") : object.interestIdsArr,
            email: object.email,
            hospitalId: object.hospitalId,
            firstName: object.firstName,
            imagePath: object.imagePath,
            isStudent: object.isStudent,
            lastName: object.lastName,
            level: object.level,
            provider: object.provider,
            pseudoName: object.pseudoName,
            uuId: object.uuId,
            universityId: object.universityId,
            studentImage: object.IdentityImage
        }

        var data = await axios.post(API_BASE_URL + "api/Account/Login", data)
        return data;
    }
    async ValidateSocialUser(object) {
         
        var data = await axios.post(API_BASE_URL + "api/Account/ValidateSocialUser", object)
        return data;
    }

    changePassword(object, email, oldPassword) {
         
        return axios.post(API_BASE_URL + "api/Account/ChangeFirstTimeStudentPassword", {
            email: email,
            oldPassword: oldPassword,
            password: object.newPassword,
        })
    }

    resetPassword(object) {
         
        return axios.post(API_BASE_URL + "api/Account/SetNewPassword", {
            id: object.id,
            token: object.token,
            password: object.newPassword,
        })
    }

    RegisterStudent(object, object2) {
        var bodyFormData = new FormData();

         ;
        bodyFormData.append("FirstName", object2.FirstName);
        bodyFormData.append("LastName", object2.LastName);
        bodyFormData.append("Image", object2.StudentImage);
        bodyFormData.append("Email", object2.Email);
        bodyFormData.append("Password", object2.Password);
        bodyFormData.append("PseudoName", object2.PseudoName);
        bodyFormData.append("CountryId", object2.CountryId);
        bodyFormData.append("LanguageId", object2.LanguageId);
        bodyFormData.append("IsStudent", object.IsStudent);
        bodyFormData.append("Level", object.Level);
        bodyFormData.append("InterestIds", object.IsStudent == true ? object.InterestIdsArr.join(", ") : object.InterestIdsArr);
        bodyFormData.append("StudentId", object2.StudentId);
        bodyFormData.append("DoctorId", object2.DoctorId);
        bodyFormData.append("UniversityId", object2.UniversityId);
        bodyFormData.append("UniversityName", object.UniversityName);
        bodyFormData.append("HospitalId", object2.HospitalId);
        bodyFormData.append("HospitalName", object.HospitalName);
        bodyFormData.append("IdentityImage", object.IdentityImage);
        bodyFormData.append("ImagePath", object.ImagePath);
        bodyFormData.append("Provider", object.Provider);
        bodyFormData.append("UuId", object.UuId);



        return axios.post(API_BASE_URL + "api/Account/RegisterUser", bodyFormData)
    }

    forgetPassword(email) {
         
        return axios.get(API_BASE_URL +  `api/Account/ForgetPassword?email=${email}`)
    }

    
    StartFreeTrialApi(UserId) {
        var bodyFormData = new FormData();
        bodyFormData.append("UserId", UserId);
        return axios.post(API_BASE_URL +  `api/Payment/StartFreeTrial`,bodyFormData)
    }


}

export default new AuthenticationService();


