import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API_BASE_URL } from "../../constant";
import PaymentServices from "../services/PaymentServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

const CheckoutForm = ({price}) => {
  const stripe = useStripe();
  const elements = useElements();
  const history=useHistory()
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    const bodyFormData = new FormData();
    bodyFormData.append("amount", price);
    bodyFormData.append("currency", "CHF");

    const response = await fetch(`${API_BASE_URL}api/Payment/createIntent`, {
      method: "POST",
      body: bodyFormData,
    });

    const { token } = await response.json();
debugger
    const paymentResult = await stripe.confirmCardPayment(token, {
      payment_method: {
        card: cardElement,
      },
    });

    setProcessing(false);

    if (paymentResult.error) {
      setError(paymentResult.error.message);
      setProcessing(false);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        
        Swal.fire(" Payment!", "" + "Payment successful!", "success");
        const res = await PaymentServices.PaymnetDone(data.id, price, paymentResult.paymentIntent.id)
        
        localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(res.data.payload));
        setProcessing(false);
        history.push("/quiz")
        window.location.reload()
      }
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "white",
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <label
        htmlFor="card-element"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0px",
          color: "#E84545",
          fontWeight: "600",
        }}
      >
        Credit or Debit Card
      </label>

      <CardElement id="card-element" options={cardStyle} />

      {error && <div className="error-message">{error}</div>}

      <button
        type="submit"
        disabled={isProcessing}
        className="pay-button"
        style={{
          backgroundColor: "#5469d4",
          color: "#ffffff",
          borderRadius: "4px",
          padding: "10px",
          width: "100%",
          marginTop: "50px",
        }}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

export default CheckoutForm;
