import React from 'react'
import { Col } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { useSelector } from 'react-redux';
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import Swal from "sweetalert2"
import AdminServices from '../../../../services/AdminServices';
import { GetStudentCohortAdmin } from '../../../redux/features/AdminSlice';
import { useDispatch } from 'react-redux';
const StudentManagment = ({ adminId, SelectedFilterData }) => {
  const dispatch = useDispatch()
  const GetStudentsCohortAdminsList = useSelector(state => state.Admin.StudentCohortAdmin)

  const StudentVerified = async (state,el, id) => {
     debugger
    await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await AdminServices.VerifyById(id,state);
         
        if (response.data.status === true) {
          Swal.fire(
            'Success!',
            response.data.message,
            'success'
          )
          dispatch(GetStudentCohortAdmin(SelectedFilterData))
        }
        else {
          Swal.fire(
            'Problem!',
            response.data.message,
            'danger'
          )
        }
      }
    })
  }

  const StudentPremium = async (el, state, id) => {
     
    await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await AdminServices.MakeUserPremiumyById(id, state, adminId);
         
        if (response.data.status === true) {
          Swal.fire(
            'Success!',
            response.data.message,
            'success'
          )
          dispatch(GetStudentCohortAdmin(SelectedFilterData))

          console.log(GetStudentsCohortAdminsList)
        }
        else {
          Swal.fire(
            'Problem!',
            response.data.message,
            'danger'
          )
        }
      }
    })
  }


  return (
    <>
      <Col md="12">
        <ReactTable
          style={{ fontSize: "12px", textAlign: "center" }}
          columns={[

            {
              Header: "User",
              accessor: "user",
              Cell: (row) => (
                <div>
                  {GetStudentsCohortAdminsList[row.index].user.firstName + " " + GetStudentsCohortAdminsList[row.index].user.lastName}
                </div>

              )
            },
            {
              Header: "Date Created",
              accessor: "creationDate",
            },

            // {
            //     Header: "Student ID",
            //     accessor: "#",
            // },

            {
              Header: "Total flags",
              accessor: "totalFlags",
            },

            {
              Header: "Flag Report",
              accessor: "data",
            },
            {
              Header: "Content Student",
              accessor: "data",
            },
            {
              Header: "Verified",
              accessor: "Verified",
              Cell: (row) => (
                <div
                  className="d-flex justify-content-center"
                >
                  {GetStudentsCohortAdminsList[row.index].user.isVerified===true ?"User Verifed":
                  <Switch bsSize="small" offColor="danger" onColor="success" onText="" offText=""
                    onChange={(el, state) => StudentVerified(el, state, GetStudentsCohortAdminsList[row.index].user.id)}
                    name='test'
                    value={GetStudentsCohortAdminsList[row.index].user.isVerified}
                  />
                }
                </div>
              ),
            },

            {
              Header: "Premium",
              accessor: "Premium",
              Cell: (row) => (
                <div
                  className="d-flex justify-content-center"
                >
                  <Switch bsSize="small" offColor="danger" onColor="success" onText="" offText=""
                    onChange={(el, state) => StudentPremium(el, state, GetStudentsCohortAdminsList[row.index].user.id)}
                    name='test'
                    value={GetStudentsCohortAdminsList[row.index].user.isPremium}
                  />

                </div>
              ),
            },

          ]}
          defaultPageSize={5}
          // showPaginationBottom={true}
          className="-striped -highlight"
          data={GetStudentsCohortAdminsList}
        // filterable
        // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

        />
      </Col>
    </>
  )
}

export default StudentManagment