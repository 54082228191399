import React from 'react';

const Term = () => {
  return (
    <div style={{ padding: '50px', color: 'white', backgroundColor: '#1D2226' }}>
      <h1 className='mb-4' style={{color:"rgb(232, 69, 69)"}}>Conditions d'utilisation</h1>
      <p><strong>Date d’entrée en vigueur : 01/01/2024</strong></p>
      
      <p>Le site Web worldofdoctors.org et ses applications constituent un service d’hébergement exploité par VB exa-med tutoring. Toute utilisation du Service est soumise aux conditions de service suivantes. Votre utilisation du Service constitue votre acceptation de ces Conditions d'utilisation.</p>

      <h2 className='text-white'>Admissibilité</h2>
      <p>L'utilisation du Service est nulle là où la loi l'interdit. Le Service est destiné aux utilisateurs majeurs, qui sont soit étudiants en médecine enregistrée dans une université ou bien médecin au bénéfice d’un diplôme reconnu...</p>

      <h2 className='text-white'>Votre compte Worldofdoctors et vos données</h2>
      <p>Si vous créez un compte sur le Service, vous êtes responsable du maintien de la sécurité de votre compte et de vos données, et vous êtes entièrement responsable de toutes les activités s'opérant sous le compte...</p>

      <h2 className='text-white'>Abonnements</h2>
      <p>Vous avez la possibilité d’acheter un plan d’abonnement qui vous permet d'accéder à une expérience améliorée, avec des fonctions et des fonctionnalités supplémentaires pour votre compte Worldofdoctors (« Abonnement »)...</p>

      <h2 className='text-white'>Conditions de l’abonnement prépayé</h2>
      <p>Les Abonnements achetés pour une Période prépayée seront automatiquement résiliés à la fin de la Période prépayée...</p>

      <h2 className='text-white'>Pour annuler votre Abonnement</h2>
      <p>Vous pouvez annuler votre Abonnement à tout moment. Si votre Abonnement est programmé pour être renouvelé automatiquement, vous devez l’annuler au moins un jour avant l’expiration de la période d’Abonnement afin d’éviter des frais supplémentaires...</p>

      <h2 className='text-white'>Annulation de notre part</h2>
      <p>Nous pouvons annuler votre adhésion actualisée à tout moment et pour quelque motif que ce soit, avec ou sans avis préalable...</p>

      <h2 className='text-white'>Remboursements</h2>
      <p>Sauf si la loi l’exige, les Abonnements achetés directement auprès de Worldofdoctors ne sont pas remboursables...</p>

      <h2 className='text-white'>Modifications de tarifs</h2>
      <p>Nous nous réservons le droit de modifier ponctuellement le tarif des Abonnements. Nous vous communiquerons à l’avance toute modification de tarifs...</p>

      <h2 className='text-white'>Contenu premium</h2>
      <p>Les utilisateurs peuvent avoir la possibilité d'acheter le contenu commercial de Worldofdoctors (« Contenu premium ») ou d'y accéder...</p>

      <h2 className='text-white'>Abonnements au contenu</h2>
      <p>L'accès au Contenu premium acheté dans le cadre d'un Abonnement au contenu est soumis aux conditions énoncées lors du processus d'achat...</p>

      <h2 className='text-white'>Codes d'accès</h2>
      <p>L'accès au Contenu premium par l'utilisation d'un code d'accès fourni par l'éditeur est soumis aux conditions définies par l'éditeur...</p>

      <h2 className='text-white'>Licence</h2>
      <p>Une fois que vous avez acheté un abonnement au contenu ou utilisé un code d'accès pour accéder au Contenu premium, vous pouvez accéder au matériel inclus pour la période de temps désignée...</p>

      <h2 className='text-white'>Restrictions</h2>
      <p>Vous ne pouvez pas utiliser le Contenu premium à des fins commerciales. En d'autres termes, vous ne pouvez pas le vendre, l'utiliser à des fins publicitaires ou marketing, ni l'utiliser d'une autre manière dans le cadre d'une activité commerciale ou lucrative...</p>

      <h2 className='text-white'>Responsabilité des collaborateurs</h2>
      <p>Si vous créez des ensembles, des commentaires via les forums de discussion, publiez du matériel sur le Service, des liens sur le Service, ou téléchargez du matériel au moyen du Service...</p>

      <h2 className='text-white'>Contenu interdit</h2>
      <p>Vous ne pouvez publier que du Contenu éducatif et lié aux études sur le Service. Voici des exemples de Contenu qu’il est interdit de publier sur ou par le biais du Service...</p>

      <h2 className='text-white'>Activités interdites</h2>
      <p>Vous ne pouvez vous engager dans des activités éducatives et liées aux études que lorsque vous utilisez le Service. Voici des exemples d'activités qui sont interdites sur le Service...</p>

      <h2 className='text-white'>Déclarations et garanties relatives au Contenu</h2>
      <p>En mettant à disposition le Contenu, vous déclarez et garantissez que le téléchargement, la copie et l'utilisation du Contenu ne portent pas atteinte aux droits de propriété...</p>

      <h2 className='text-white'>Licence</h2>
      <p>En soumettant du Contenu ou tout autre élément à Worldofdoctors afin qu’il soit inclus sur le Service, vous accordez à Worldofdoctors une licence de durée déterminée, libre de droits à l’échelle mondiale...</p>

      <h2 className='text-white'>Rémunération des utilisateurs-auteurs</h2>
      <p>Les utilisateurs auteurs qui ont publié du contenu premium peuvent demander une rémunération. Chaque contenu premium liké génère un Medcoin...</p>

      <h2 className='text-white'>Responsabilité des utilisateurs du service</h2>
      <p>Worldofdoctors n'a pas examiné et ne peut pas examiner tout le contenu publié sur le service et ne peut donc pas être responsable du contenu, de l'utilisation ou des effets de ce matériel...</p>

      <h2 className='text-white'>Infraction aux droits d’auteur et politique de la DMCA</h2>
      <p>Worldofdoctors respecte les droits de propriété intellectuelle d’autrui. Si vous pensez que le matériel se trouvant sur Worldofdoctors ou qui y est lié viole vos droits d’auteur, nous vous encourageons à nous prévenir...</p>

      <h2 className='text-white'>Marques</h2>
      <p>Worldofdoctors, Worldofdoctors.com, Worldofdoctors Ads, la marque et le logo Worldofdoctors ainsi que les autres marques de commerce...</p>

      <h2 className='text-white'>Modifications apportées aux Conditions</h2>
      <p>Le Service, y compris et sans s’y limiter, le contenu qui s’y trouve disponible, les caractéristiques des produits et des Abonnements...</p>

      <h2 className='text-white'>LIMITATION DES GARANTIES DE WORLDOFDOCTORS</h2>
      <p>SAUF DISPOSITION EXPRESSE CONTRAIRE, TOUT LE CONTENU PUBLIÉ OU DISPONIBLE SUR LE SERVICE EST FOURNI « TEL QUEL »...</p>

      <h2 className='text-white'>LIMITATION DE LA RESPONSABILITÉ DE WORLDOFDOCTORS</h2>
      <p>SAUF INDICATION CONTRAIRE EXPRESSE, EN AUCUN CAS WORLDOFDOCTORS, SES FILIALES, SES FOURNISSEURS OU SES CONCÉDANTS DE LICENCE NE SERONT RESPONSABLES ENVERS VOUS OU TOUTE AUTRE PARTIE DES DOMMAGES...</p>

      <h2 className='text-white'>Différends avec Worldofdoctors</h2>
      <p>En cas de différend entre vous et Worldofdoctors découlant des présentes Conditions d'utilisation ou de votre utilisation du Service...</p>

      <h2 className='text-white'>Arbitrage exécutoire</h2>
      <p>Tout différend découlant ou résultant des présentes Conditions de votre utilisation de nos Services sera réglé par arbitrage exécutoire, mené par l'American Arbitration Association...</p>

      <h2 className='text-white'>Loi applicable</h2>
      <p>Worldofdoctors est situé en Suisse et son siège social se trouve à Genèce. Sauf dans la mesure où une loi applicable en dispose autrement...</p>

      <h2 className='text-white'>Lieu</h2>
      <p>Tout différend faisant l'objet d'un arbitrage exécutoire sera arbitré dans le canton de Genève, Suisse...</p>

      <h2 className='text-white'>Représentation générale et garantie</h2>
      <p>Vous déclarez et garantissez que l'utilisation que vous faites du Service est en conformité avec nos conditions d'utilisation...</p>

      <h2 className='text-white'>Indemnisation</h2>
      <p>Vous acceptez de défendre, d’indemniser et d’exonérer Worldofdoctors, ses filiales, ses sous-traitants, ses concédants de licence ainsi que leurs administrateurs, dirigeants, employés et représentants de et contre toutes les réclamations et dépenses...</p>

      <h2 className='text-white'>Divers</h2>
      <p>Vous acceptez de défendre, d'indemniser et d'exonérer Worldofdoctors, ses sous-traitants, ses concédants de licence ainsi que leurs administrateurs, dirigeants, employés et représentants de et contre toutes les réclamations et dépenses...</p>

      <h2 className='text-white'>Divisibilité</h2>
      <p>Si une partie de ces Conditions d'utilisation est jugée invalide ou inapplicable, celle-ci sera interprétée de manière à refléter l'intention initiale du rédacteur...</p>

      <h2 className='text-white'>Non-renonciation aux droits</h2>
      <p>La renonciation par l'une des parties à une condition de ces Conditions d'utilisation ou à toute infraction à ces dernières, dans quelque circonstance que ce soit, n'implique pas la renonciation à cette condition...</p>

      <h2 className='text-white'>Cession</h2>
      <p>Vous pouvez céder vos droits en vertu de ces Conditions d'utilisation à toute partie qui consent et accepte d'être liée par ses conditions...</p>

      <h2 className='text-white'>Survie des conditions</h2>
      <p>Les présentes Conditions continuent de s'appliquer, même après que vous avez fermé votre compte ou cessé d'utiliser le Service...</p>

      <h2 className='text-white'>Politique concernant le non-respect des droits d'auteur</h2>
      <p>Worldofdoctors s'engage à protéger les droits de propriété intellectuelle des tiers...</p>
    </div>
  );
};

export default Term;
